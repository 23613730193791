<template>
<div :key="`key_${key}`">
    <div class="kt-portlet__body">
        <div
            class="align-items-center pl-2"
            :class="{'row': schoolStaffId}"
        >
            <div
                class="kt-searchbar"
                :class="{'col-lg-9': schoolStaffId}"
            >
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span
                            id="basic-addon1"
                            class="input-group-text"
                        ><i class="fa fa-search" /></span>
                    </div>
                    <input
                        v-model="filter"
                        type="text"
                        class="form-control"
                        placeholder="Search"
                    >
                </div>
            </div>
            <div
                v-if="schoolStaffId"
                class="col-lg-3"
            >
                <button
                    type="button"
                    class="btn btn-clean btn-sm btn-bold"
                    @click.stop.prevent="openCreateChat"
                >
                    New Chat
                </button>
            </div>
        </div>
        <div class="kt-widget kt-widget--users kt-mt-20">
            <div
                class="kt-scroll"
                style="height: 423px; overflow-x: hidden; overflow-y: hidden;"
            >
                <div class="kt-widget__items">
                    <div
                        v-for="(group, idx) in filteredGroups"
                        :key="`group_${group.communicationGroupId}_${idx}`"
                        class="kt-widget__item"
                        @click.stop.prevent="selectGroup(group)"
                    >
                        <span v-if="!group.unReadMessages" class="kt-media kt-media--circle">
                            <UserAvatar
                                :avatar-user="group.authorUser"
                                style="max-width: 40px; width: 40px; height: 40px;"
                            >
                                <div class="kt-badge kt-badge--lg kt-badge--success">
                                    {{ group.authorUser ? `${group.authorUser.lastName.substring(0,2)}` : '-' }}
                                </div>
                            </UserAvatar>
                        </span>
                        <span v-else class="kt-media kt-media--circle">
                            <div class="kt-badge kt-badge--lg kt-badge--danger kt-font-boldest">
                                {{ group.unReadMessages }}
                            </div>
                        </span>
                        <div class="kt-widget__info">
                            <div class="kt-widget__section">
                                <a
                                    href="#"
                                    class="kt-widget__username group-name"
                                >{{ group.groupName }}</a>
                            </div>
                            <span class="kt-widget__desc">
                                {{ group.messages.length ? group.messages[group.messages.length - 1].message : 'No group messages' }}
                            </span>
                        </div>
                        <div class="kt-widget__action">
                            <span v-if="group.unReadMessages" class="kt-font-danger">
                                <span class="kt-badge kt-badge--danger kt-badge--dot mr-2" />
                                {{ group.unReadMessages }} Messages
                            </span>
                            <span v-else class="kt-widget__date">{{ group.timeStamp }}</span>
                        </div>
                    </div>
                </div>
                <div
                    class="ps__rail-x"
                    style="left: 0px; bottom: 0px;"
                >
                    <div
                        class="ps__thumb-x"
                        tabindex="0"
                        style="left: 0px; width: 0px;"
                    />
                </div><div
                    class="ps__rail-y"
                    style="top: 0px; height: 423px; right: -2px;"
                >
                    <div
                        class="ps__thumb-y"
                        tabindex="0"
                        style="top: 0px; height: 155px;"
                    />
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { mapState } from 'vuex';
import UserAvatar from '../UserAvatar.vue';
import Types from '../../store/Types';
import communicationMixins from '../../store/mixins/communicationMixins';

export default {
    name: 'ChatGroups',
    components: {
        UserAvatar,
    },
    mixins: [communicationMixins],
    props: {
        isForPanel: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            key: 0,
            filter: '',
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
        }),
        schoolStaffId() {
            const { user } = this;
            const { school } = user;
            const { schoolStaffId } = school;
            return schoolStaffId;
        },
        groups() {
            return this.$_communicationMixins_getGroups();
        },
        filteredGroups() {
            const { groups, filter } = this;
            return groups.filter((g) => {
                const { search } = g;
                if (!filter) return true;
                return search.includes(filter.toLowerCase());
            }).sort((a, b) => {
                const newestMessageA = a.messages.length ? a.messages.sort((x, y) => x.created - y.created)[0].created : null;
                const newestMessageB = b.messages.length ? b.messages.sort((x, y) => x.created - y.created)[0].created : null;
                return (newestMessageA || a.created) - (newestMessageB || b.created);
            });
        },
        showingPopupChat() {
            return this.$store.state.communication.popupChat;
        },
    },
    methods: {
        openCreateChat() {
            this.$store.commit(Types.mutations.TOGGLE_CREATE_COMMUNICATION_GROUP);
        },
        selectGroup(group) {
            const { isForPanel, showingPopupChat } = this;
            this.$store.commit(Types.mutations.SET_SELECTED_COMMUNICATION_GROUP, group);
            if (!showingPopupChat && isForPanel) {
                this.$store.commit(Types.mutations.TOGGLE_COMMUNICATION_POP_UP_CHAT);
            }
        },
    },
};
</script>

<style scoped>
.group-name {
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    line-height: 1.2em !important;
    height: 1.2em !important;
    max-width: 280px !important;
    overflow-x: hidden !important;
}
</style>
