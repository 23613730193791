import moment from 'moment';
import { getTeachers } from './teacherMixins';
import { getStudents } from './studentMixins';

const communicationMixins = {
    methods: {
        $_communicationMixins_getMessageTotalUnReadCount() {
            return this.$store.state.database.messages.filter((m) => !m.deleted && !m.messageRead).length;
        },
        $_communicationMixins_getGroups() {
            return getCommunicationGroups(this.$store.state);
        },
    },
};

function getCommunicationGroups(state) {
    const { database, user } = state;
    const { communicationGroups, studentGuardians } = database;
    const teachers = getTeachers(database);
    const students = getStudents(database);
    const { school } = user;
    const { schoolStaffId, studentGuardianId, studentId } = school;

    const guardians = studentGuardians.map((g) => {
        const guardian = { ...g };
        guardian.lastName = g.guardianLastName;
        guardian.firstName = g.guardianFirstName;
        return guardian;
    });

    return communicationGroups.map((g) => {
        const group = { ...g };
        const u = teachers.find((t) => t.schoolStaffId == group.schoolStaffId) || null;
        group.authorUser = u;
        group.members = group.members.map((m) => {
            const member = { ...m };
            if (member.schoolStaffId) {
                member.user = teachers.find((t) => t.schoolStaffId == member.schoolStaffId) || null;
            } else if (member.studentGuardianId) {
                member.user = guardians.find((gu) => gu.studentGuardianId == member.studentGuardianId) || null;
            } else if (member.studentId) {
                member.user = students.find((s) => s.studentId == member.studentId) || null;
            }
            return member;
        });

        const altGroupName = group.members.filter((m) => {
            if (schoolStaffId) return m.schoolStaffId != schoolStaffId;
            if (studentGuardianId) return m.studentGuardianId != studentGuardianId;
            if (studentId) return m.studentId != studentId;
            return true;
        }).map((m) => `${m.user ? m.user.firstName || '' : ''} ${m.user ? m.user.lastName || '' : ''}`).join(', ');

        group.groupName = group.groupName || altGroupName;

        group.messages = group.messages.sort((a, b) => moment(a.created).diff(moment(b.created)));
        group.timeStampFull = group.messages.length ? moment(group.messages[group.messages.length - 1].created).format('YYYY-MM-DD HH:mm:ss') : moment(group.created).format('YYYY-MM-DD HH:mm:ss');
        group.timeStamp = group.messages.length ? moment(group.messages[group.messages.length - 1].created).format('MMM Do, YYYY') : moment(group.created).format('MMM Do, YYYY');

        group.search = `${group.groupName || ''} ${altGroupName || ''}`.toLowerCase();

        const myMemberId = group.members.find((m) => {
            if (schoolStaffId) return m.schoolStaffId == schoolStaffId;
            if (studentGuardianId) return m.studentGuardianId == studentGuardianId;
            if (studentId) return m.studentId == studentId;
            return false;
        })?.communicationGroupMemberId || null;

        group.unReadMessages = group.messages.filter((m) => {
            const { readBy } = m;
            if (!readBy || !readBy.length) return true;
            return !readBy.includes(myMemberId);
        }).length;

        return group;
    }).sort((a, b) => (a.timeStampFull < b.timeStampFull ? 1 : -1));
}

export {
    communicationMixins as default,
    getCommunicationGroups,
};
