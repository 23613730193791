var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { key: `key_${_vm.key}` }, [
    _c("div", { staticClass: "kt-portlet__body" }, [
      _c(
        "div",
        {
          staticClass: "align-items-center pl-2",
          class: { row: _vm.schoolStaffId },
        },
        [
          _c(
            "div",
            {
              staticClass: "kt-searchbar",
              class: { "col-lg-9": _vm.schoolStaffId },
            },
            [
              _c("div", { staticClass: "input-group" }, [
                _vm._m(0),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filter,
                      expression: "filter",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text", placeholder: "Search" },
                  domProps: { value: _vm.filter },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.filter = $event.target.value
                    },
                  },
                }),
              ]),
            ]
          ),
          _vm.schoolStaffId
            ? _c("div", { staticClass: "col-lg-3" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-clean btn-sm btn-bold",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.openCreateChat.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v(" New Chat ")]
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c("div", { staticClass: "kt-widget kt-widget--users kt-mt-20" }, [
        _c(
          "div",
          {
            staticClass: "kt-scroll",
            staticStyle: {
              height: "423px",
              "overflow-x": "hidden",
              "overflow-y": "hidden",
            },
          },
          [
            _c(
              "div",
              { staticClass: "kt-widget__items" },
              _vm._l(_vm.filteredGroups, function (group, idx) {
                return _c(
                  "div",
                  {
                    key: `group_${group.communicationGroupId}_${idx}`,
                    staticClass: "kt-widget__item",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.selectGroup(group)
                      },
                    },
                  },
                  [
                    !group.unReadMessages
                      ? _c(
                          "span",
                          { staticClass: "kt-media kt-media--circle" },
                          [
                            _c(
                              "UserAvatar",
                              {
                                staticStyle: {
                                  "max-width": "40px",
                                  width: "40px",
                                  height: "40px",
                                },
                                attrs: { "avatar-user": group.authorUser },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "kt-badge kt-badge--lg kt-badge--success",
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          group.authorUser
                                            ? `${group.authorUser.lastName.substring(
                                                0,
                                                2
                                              )}`
                                            : "-"
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      : _c(
                          "span",
                          { staticClass: "kt-media kt-media--circle" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "kt-badge kt-badge--lg kt-badge--danger kt-font-boldest",
                              },
                              [_vm._v(" " + _vm._s(group.unReadMessages) + " ")]
                            ),
                          ]
                        ),
                    _c("div", { staticClass: "kt-widget__info" }, [
                      _c("div", { staticClass: "kt-widget__section" }, [
                        _c(
                          "a",
                          {
                            staticClass: "kt-widget__username group-name",
                            attrs: { href: "#" },
                          },
                          [_vm._v(_vm._s(group.groupName))]
                        ),
                      ]),
                      _c("span", { staticClass: "kt-widget__desc" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              group.messages.length
                                ? group.messages[group.messages.length - 1]
                                    .message
                                : "No group messages"
                            ) +
                            " "
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "kt-widget__action" }, [
                      group.unReadMessages
                        ? _c("span", { staticClass: "kt-font-danger" }, [
                            _c("span", {
                              staticClass:
                                "kt-badge kt-badge--danger kt-badge--dot mr-2",
                            }),
                            _vm._v(
                              " " + _vm._s(group.unReadMessages) + " Messages "
                            ),
                          ])
                        : _c("span", { staticClass: "kt-widget__date" }, [
                            _vm._v(_vm._s(group.timeStamp)),
                          ]),
                    ]),
                  ]
                )
              }),
              0
            ),
            _vm._m(1),
            _vm._m(2),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c(
        "span",
        { staticClass: "input-group-text", attrs: { id: "basic-addon1" } },
        [_c("i", { staticClass: "fa fa-search" })]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "ps__rail-x",
        staticStyle: { left: "0px", bottom: "0px" },
      },
      [
        _c("div", {
          staticClass: "ps__thumb-x",
          staticStyle: { left: "0px", width: "0px" },
          attrs: { tabindex: "0" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "ps__rail-y",
        staticStyle: { top: "0px", height: "423px", right: "-2px" },
      },
      [
        _c("div", {
          staticClass: "ps__thumb-y",
          staticStyle: { top: "0px", height: "155px" },
          attrs: { tabindex: "0" },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }